@if ((user | async)?.isAnonymous && (!hideable || (hideable && !isHidden()))) {
<mat-card class="banner banner-info" [class.banner-gray]="bannerStyle === 'gray'" appearance="outlined">
  <mat-card-content class="banner-main">
    <mat-icon class="banner-icon" color="primary"> info </mat-icon>
    <div class="banner-content">
      <p>
        Hey anonymous user! 👋 Sign up for a free PlanningPoker.live account for the following benefits:
      </p>
      <ul>
        <li>
          <mat-icon>toll</mat-icon><span>Get one <strong>free credit</strong> every month</span>
        </li>
        <li>
          <mat-icon>history</mat-icon><span>Store your session and voting history securely</span>
        </li>
        <li>
          <mat-icon>account_circle</mat-icon>
          <span>Use the same account across multiple devices and platforms</span>
        </li>
        <li>
          <mat-icon>link</mat-icon>
          <span>...and more: import tickets from JIRA/Linear, create an organization or
            generate a summary with PokerBot!</span>
        </li>
      </ul>
    </div>
  </mat-card-content>
  <mat-card-actions class="banner-action">
    <button mat-flat-button color="primary" (click)="onSignUpClicked.next()" [disabled]="isBusy | async"
      id="create-account-button-banner">
      <mat-icon>account_circle</mat-icon>
      Create an account
    </button>
    <button mat-button color="primary" (click)="onSignInClicked.next()" [disabled]="isBusy | async"
      id="sign-in-button-banner"
      matTooltip="Sign in to an existing account. This will drop your current anonymous account and sign you into your existing account instead.">
      <mat-icon>badge</mat-icon>
      Sign in
    </button>
    @if (hideable) {
    <button mat-button color="secondary" (click)="hideBanner()">
      <mat-icon>schedule</mat-icon>
      Maybe later
    </button>
    }
  </mat-card-actions>
</mat-card>
}